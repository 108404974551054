
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































































































































.footer {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 52px, xxl: 98px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 52px, xxl: 98px));

  color: $c-beige;
  background-color: $c-brown;
}

.footer__column {
  display: flex;
  flex-direction: column;
}

.footer__logo {
  // prettier-ignore
  @include fluid(width, (xxs: 43px, xxl: 49px));

  margin-bottom: 4.2rem;
  fill: currentColor;
}

.footer__baseline {
  // prettier-ignore
  @include fluid(width, (xxs: 200px, xxl: 347px));
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 50px, xxl: 100px));

  fill: currentColor;
}

.footer__nav {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer__link {
  @extend %button-nostyle;

  position: relative;
  overflow: hidden;
  width: fit-content;
  padding-bottom: 0.3rem;
  color: $c-beige;
  text-decoration: none;

  &::after,
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background: $c-beige;
    transition: transform 0.15s;
  }

  &::before {
    z-index: 2;
    right: 100%;
    left: auto;
    background: $c-skin;
  }

  &:hover {
    &::before {
      transform: translateX(100%);
    }
  }
}

.footer__info__title {
  margin-bottom: 1.9rem;
  color: $c-skin;
}

.footer__info__content {
  margin-bottom: 4rem;

  ::v-deep {
    p {
      margin-bottom: 0;
      font-size: 1.4rem;
    }
  }

  @include mq(m) {
    ::v-deep {
      p {
        font-size: 1.6rem;
      }
    }
  }
}

.socials {
  display: flex;
  margin: 0;
  margin-bottom: 5rem;
  padding: 0;
  list-style-type: none;

  @include mq(m) {
    margin-bottom: 0;
  }
}

.socials__item {
  margin: 0;
  padding: 0;

  &:not(:last-of-type) {
    margin-right: 2rem;
  }
}

.socials__link {
  color: currentColor;
  text-decoration: none;

  &:hover {
    color: $c-skin;
  }
}

.socials__link__svg {
  display: block;
  width: 1.5rem;
  fill: currentColor;
}

.footer__places {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @include mq($until: xxl) {
    display: none;
  }
}

.footer__places__item {
  padding: 4rem;
  border-left: 1px solid $c-beige;
  transition: opacity 0.15s;

  svg {
    fill: $c-beige;
  }

  &:hover {
    opacity: 0.7;
  }
}
